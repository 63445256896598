import { CustomerAddress } from '@Core/@types/skyway'

export type ConfigOptions = {
  APP_ENV: string
  APP_URL: string
  PORT: string
  GRAPHQL_ENDPOINT: string
  QRCODE_ENDPOINT: string
  PDF_ENDPOINT: string
  CURRENCY_SYMBOL: string
  DEFAULT_COUNTRY_NAME: string
  DEFAULT_COUNTRY_CODE: string
  ASSET_PATH: string
  CMS_ASSET_PATH: string
  CURRENCY: string
  LOCALE: string
  SYSTEM_ERROR_MESSAGE: string
  MARKETING_WHATS_ON?: string
  MARKETING_TERMS_AND_CONDITIONS?: string
  LOQATE_KEY: string
  URLS: { [key: string]: string }
  GOOGLE_CLIENT_ID: any
  GOOGLE_RECAPTCHA_CLIENT_ID: any
  GOOGLE_TAG_MANAGER: any
  GOOGLE_API_KEY: any
  ORGANISATION: string
  DEFAULT_ADDRESS: CustomerAddress
  MOCK_ADDRESS: CustomerAddress
  BASKET_DONATION_FUND: number
  CLIENT_NAME: string
  REGISTRATION_TYPE_REF: string
  MEMBERSHIP_FUND_REFS: string[]
  ON_ACCOUNT_PAYMENT_REF: string
  LOYALTY_SCHEME: { [key: string]: any }
  DELIVERY_METHOD_TYPES: object
  GIFT_AID_TYPE: number
  IGNORE_MEMBERSHIPS: number[]
  DIRECT_DEBIT_DISCOUNT: number
  ALLOCATION_REFS: { [key: string]: string }
  BOX_OFFICE_EMAIL: string
  BOX_OFFICE_PHONE: string
  ORDER_HISTORY_BLACKLIST: number[]
  BASKET_TIMEOUT: number
  SEAT_MAP_SHOW_ALL_SCREENS: boolean
  TESSITURA_VERSION: any
  CREATIVE_STUDIOS_MEMBERSHIP_REF: string
  FEE_TYPE_REFS: { [key: string]: string[] }
  CROWDHANDLER_DOMAIN: any
  CROWDHANDLER_PUBLIC_KEY: any
  PREVIEW_CONSTITUENCY_REFS: string[]
}

export const config: ConfigOptions = {
  APP_ENV: `${process.env.APP_ENV}`,
  APP_URL: `${process.env.APP_URL}`,
  PORT: `${process.env.PORT}`,
  GRAPHQL_ENDPOINT: `${process.env.skywayEndpoint}`,
  QRCODE_ENDPOINT: `${process.env.qrEndpoint}`,
  PDF_ENDPOINT: `${process.env.pdfEndpoint}`,
  CURRENCY_SYMBOL: '£',
  DEFAULT_COUNTRY_NAME: 'GB',
  DEFAULT_COUNTRY_CODE: '209',
  ASSET_PATH: `${process.env.assetPath}`,
  CMS_ASSET_PATH: `${process.env.cmsAssetPath}`,
  CURRENCY: `${process.env.currency}`,
  LOCALE: `${process.env.locale}`,
  SYSTEM_ERROR_MESSAGE:
    'There is a temporary error with our ticketing system.  Please try again in a moment.',
  LOQATE_KEY: `${process.env.LOQATE_KEY}`,
  URLS: {
    select_seats: '/select-seats/',
    best_available: '/best-available/',
    general_admission: '/general-admission/',
    timed_entry: '/timed-entry/',
    memberships: '/support-us/membership/',
    calendar: '/calendar/',
    donations: '/donations',
    select_area: '/select-area/',
    forgotten_password: '/forgotten-password/',
    gift_voucher: '/gifts/voucher/',
    gift_membership: '/gifts/membership/',
    sign_in: '/sign-in/',
    basket: '/basket/',
    account: '/account/',
    register: '/register/',
    payment: '/payment/',
    success: '/payment/success/',
    membership_details: '/membership-details/',
    delivery: '/delivery/',
    whats_on: '/whats-on/',
    event: '/events/event/',
    classes: '/workshop/',
    contact: '/contact/',
    privacy_policy: '/privacy-policy/',
    terms_and_conditions: '/terms-policies/',
    cookies_policy: '/terms-policies',
    pending: '/orders/pending/',
  },
  // @ts-ignore
  GOOGLE_CLIENT_ID: process.env.google ? `${process.env.google.clientId}` : '',
  // @ts-ignore
  GOOGLE_RECAPTCHA_CLIENT_ID: process.env.google
    ? // @ts-ignore
      `${process.env.google.recaptchaClientId}`
    : '',
  // @ts-ignore
  GOOGLE_TAG_MANAGER: process.env.google
    ? // @ts-ignore
      `${process.env.google.tagManagerId}`
    : '',
  // @ts-ignore
  GOOGLE_API_KEY: process.env.google ? `${process.env.google.mapsApiKey}` : '',
  DEFAULT_ADDRESS: {
    street1: 'Skyway Gallery',
    street2: '',
    city: 'Glasgow',
    state: '',
    country_ref: '209',
    postal_code: 'G2 5TY',
  },
  MOCK_ADDRESS: {
    street1: 'Roundhouse mock',
    street2: '',
    city: 'London',
    state: '',
    country_ref: '209',
    type_ref: 3,
    postal_code: 'SW1A 0AA',
  },
  ORGANISATION: 'Skyway',
  BASKET_DONATION_FUND: 42,
  CLIENT_NAME: 'Skyway',
  REGISTRATION_TYPE_REF: '1',
  MEMBERSHIP_FUND_REFS: ['42', '117'],
  ON_ACCOUNT_PAYMENT_REF: '62',
  LOYALTY_SCHEME: {
    enabled: true,
    constituency: 88,
    points_per_pound: 10,
    points_worth: 1,
    joining_bonus: 300,
    enable_gift_back: false,
    payment_method: 275,
    invalid_price_types: [636],
  },
  DELIVERY_METHOD_TYPES: {
    email: 6,
    post: 1,
    cobo: -1,
  },
  GIFT_AID_TYPE: 2,
  IGNORE_MEMBERSHIPS: [59],
  DIRECT_DEBIT_DISCOUNT: 5,
  BOX_OFFICE_EMAIL: 'box.office@skyway.uk',
  BOX_OFFICE_PHONE: '0800 123 4567',
  ORDER_HISTORY_BLACKLIST: [21809, 21869],
  BASKET_TIMEOUT: 15,
  ALLOCATION_REFS: {
    Accessible: '279',
    Wheelchair: '282',
    AudioDescribed: '289',
    Captioned: '290',
    Bsl:
      process.env.skywayEndpoint ==
      'https://roundhouse.staging.afterdigital.io/graphql'
        ? '321'
        : '322',
    Companion:
      process.env.skywayEndpoint ==
      'https://roundhouse.staging.afterdigital.io/graphql'
        ? '322'
        : '321',
  },
  SEAT_MAP_SHOW_ALL_SCREENS: true,
  // @ts-ignore
  CROWDHANDLER_DOMAIN: process.env.crowdHandler
    ? // @ts-ignore
      `${process.env.crowdHandler.domain}`
    : '',
  // @ts-ignore
  CROWDHANDLER_PUBLIC_KEY: process.env.crowdHandler
    ? // @ts-ignore
      `${process.env.crowdHandler.publicKey}`
    : '',
  TESSITURA_VERSION: `${process.env.TESSITURA_VERSION}` || '15',
  CREATIVE_STUDIOS_MEMBERSHIP_REF: '25',
  FEE_TYPE_REFS: {
    'Booking Fee': ['215', '218', '223', '227', '226', '242', '243'],
    'Restoration Levy': ['220', '216', '228', '244'],
  },
  PREVIEW_CONSTITUENCY_REFS: ['46'],
}
